import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbrevCurrency'
})
export class AbbrevCurrencyPipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    return this.formatToUnits(value, 1);
  }

  private formatToUnits(number, precision) {
    const abbrev = ['', 'thousand', 'million', 'billion', 'trillion'];
    const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
    const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
    const suffix = abbrev[order];

    const currency : string = String((number / Math.pow(10, order * 3)).toFixed(precision));
    const last = currency.charAt(currency.length - 1); 

    const output_currency = (last == "0") ? currency.split('.')[0] : (number / Math.pow(10, order * 3)).toFixed(precision)

    return `${output_currency} ${suffix}`;
  }

}
