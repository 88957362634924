import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MaterialModule } from './modules/material/material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PercentPipe } from './pipes/percent.pipe';
import { NavbarComponent } from './components/views/navbar/navbar.component';
import { RequestDemoDialogComponent } from './components/views/request-demo-dialog/request-demo-dialog.component';
import { EmployeeInputViewComponent } from './components/views/employee-input-view/employee-input-view.component'
import { RequestDemoViewComponent } from './components/views/request-demo-view/request-demo-view.component';
import { MymeeCircleSvgComponent } from './components/svgs/mymee-circle-svg/mymee-circle-svg.component';
import { AbbrevCurrencyPipe } from './pipes/abbrev-currency.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PercentPipe,
    NavbarComponent,
    RequestDemoDialogComponent,
    EmployeeInputViewComponent,
    RequestDemoViewComponent,
    MymeeCircleSvgComponent,
    AbbrevCurrencyPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule
  ],
  providers: [{provide:LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent],
  entryComponents:[RequestDemoDialogComponent]
})
export class AppModule { }
