import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestDemoDialogComponent } from '../request-demo-dialog/request-demo-dialog.component';

@Component({
  selector: 'app-request-demo-view',
  templateUrl: './request-demo-view.component.html',
  styleUrls: ['./request-demo-view.component.css']
})
export class RequestDemoViewComponent implements OnInit {

  @Input() potential_savings : number = 0;

  constructor(private dialog : MatDialog) { 

  }

  ngOnInit() {

  }

  on_request_demo_pressed() {
    this.dialog.open(RequestDemoDialogComponent);
  }
}
