import { Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestDemoDialogComponent } from './components/views/request-demo-dialog/request-demo-dialog.component';
import { EmployeeInputViewComponent } from './components/views/employee-input-view/employee-input-view.component';
import { RequestDemoViewComponent } from './components/views/request-demo-view/request-demo-view.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  @ViewChild(EmployeeInputViewComponent, {static:false}) employee_input_view : EmployeeInputViewComponent;
  @ViewChild(RequestDemoViewComponent, {static:true}) request_demo_view : RequestDemoViewComponent;

  private readonly num_animation_length = 750;

  number_of_employees : number = 5000
  potentially_affected_employees : number = 0;
  annual_spend_drug_costs : number = 0;
  potential_savings : number = 0;

  constructor(public dialog : MatDialog){
  }

  ngAfterViewInit() {
    this.recalculate_all(this.employee_input_view.number_of_employees, true);
  }

  on_number_of_employees_changed(employees : number) {
    this.recalculate_all(employees, false);
  }

  on_request_demo() {
    this.dialog.open(RequestDemoDialogComponent, {});
  }

  private recalculate_all(employees, animated) {
    this.potentially_affected_employees = Math.round(employees * .1);
    this.annual_spend_drug_costs = this.potentially_affected_employees * 30000;
    this.potential_savings = this.annual_spend_drug_costs * 0.3 * 0.5;

    if(animated) {
      this.animateValue(0, this.number_of_employees, this.num_animation_length, (val) => {
        this.number_of_employees = val;
      })
      
      this.animateValue(0, this.potentially_affected_employees, this.num_animation_length, (val) => {
        this.potentially_affected_employees = val;
      })

      this.animateValue(0, this.annual_spend_drug_costs, this.num_animation_length, (val) => {
        this.annual_spend_drug_costs = val;
      })

      this.animateValue(0, this.potential_savings, this.num_animation_length, (val) => {
        this.potential_savings = val;
      })
    }
  }


  private animateValue(start, end, duration, on_update) {
    var range = end - start;
    var minTimer = 50;
    var stepTime = Math.abs(Math.floor(duration / range));
    
    stepTime = Math.max(stepTime, minTimer);
    
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;
  
    function run() {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - (remaining * range));
        on_update(value);
        if (value == end) {
            clearInterval(timer);
        }
    }
    
    timer = setInterval(run, stepTime);
    run();
  }
}


