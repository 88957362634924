import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-employee-input-view',
  templateUrl: './employee-input-view.component.html',
  styleUrls: ['./employee-input-view.component.css']
})
export class EmployeeInputViewComponent implements OnInit, AfterViewInit {

  @Output() on_number_of_employees_changed : EventEmitter<number> = new EventEmitter();
  @Input() affected_by_auto_immune_disease : number = 0;
  @Input() total_annual_spend : number = 0;

  @Input() number_of_employees : number = 0;
  
  constructor() { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
  }

  on_number_of_employees_text_changed() {
    this.on_number_of_employees_changed.emit(this.number_of_employees);
  }

  on_number_of_employees_slider_changed(event : any) {
    this.number_of_employees = event.value;
    this.on_number_of_employees_changed.emit(this.number_of_employees);
  }
}
