import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mymee-circle-svg',
  templateUrl: './mymee-circle-svg.component.html',
  styleUrls: ['./mymee-circle-svg.component.css']
})
export class MymeeCircleSvgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
