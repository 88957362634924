import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-request-demo-dialog',
  templateUrl: './request-demo-dialog.component.html',
  styleUrls: ['./request-demo-dialog.component.css']
})
export class RequestDemoDialogComponent implements OnInit {

  public form_group : FormGroup;

  constructor(private dialog_ref : MatDialogRef<RequestDemoDialogComponent>, private form_builder : FormBuilder) {
    this.form_group = this.form_builder.group({
      'first_name':['', Validators.required],
      'last_name':['', Validators.required],
      'email_address':['', Validators.required],
      'phone_number':['', Validators.required],
      'company_name':['', Validators.required]
    });
  }

  ngOnInit() {
    
  }

  on_cancel_pressed() {
    this.dialog_ref.close();
  }

  on_submit_pressed() {
    if(this.form_group.valid) {

    }
    this.dialog_ref.close();
  }

}
